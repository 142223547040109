import { useContext, useEffect } from "react";
import UserContext from "../context/UserContext";
import { Navigate } from "react-router-dom";

export default function Logout() {
  const { actions } = useContext(UserContext);

  useEffect(() => {
    actions.signOut();
  });
  
  return <Navigate to="/" replace />;
}
