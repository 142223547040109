import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItems from "../components/listItems";
import QuestionBoard from "../components/QuestionBoard";
import Question from "../components/Question";
import ScoreCard from "../components/ScoreCard";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import WelcomeBoard from "../components/WelcomeBoard";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#E43D12", // Customize primary color
    },
    secondary: {
      main: "#dc004e", // Customize secondary color
    },
    background: {
      default: "#EBE9E1", // Customize background color
    },
  },
});

export default function Dashboard() {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [selectedListItem, setSelectedListItem] = React.useState("Dashboard");
  const [selectedQuestionSet, setSelectedQuestionSet] = React.useState(0);
  const [currentQuestion, setCurrentQuestion] = React.useState(0);
  const [score, setScore] = React.useState(0);
  const [answerLog, setAnswerLog] = React.useState([]);
  const navigate = useNavigate();
  const [questionSetData, setQuestionSetData] = React.useState({
    questionType: '',
    questionCount: 0,
  });
  const [questionData, setQuestionData] = React.useState([]);

  const displayListItem = () => {
    if (selectedListItem === "Question") {
      return (
        <Question
          selectedQuestionSet={selectedQuestionSet}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
          setScore={setScore}
          setSelectedListItem={setSelectedListItem}
          setAnswerLog={setAnswerLog}
          questionSetData={questionSetData}
          questionData={questionData}
        />
      );
    } else if (selectedListItem === "Score") {
      return (
        <ScoreCard
          score={score}
          totalQuestion={questionSetData.questionCount}
          answerLog={answerLog}
        />
      );
    } else if (selectedListItem === "QuestionSet") {
      return (
        <QuestionBoard
          questionSetData={questionSetData}
          setQuestionSetData={setQuestionSetData}
          setSelectedListItem={setSelectedListItem}
          selectedQuestionSet={selectedQuestionSet}
          setQuestionData={setQuestionData}
        />
      );
    } else {
      return <WelcomeBoard />;
    }
  };

  const resetUserSelectedData = (selectedSet) => {
    setSelectedQuestionSet(selectedSet);
    setSelectedListItem("QuestionSet");
    setQuestionSetData(prevState => ({
      ...prevState,
      questionCount: 0,
      questionType: ""
    }));
    setCurrentQuestion(0);
    setScore(0);
    setAnswerLog([]);
    setQuestionData([]);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Nihongo mock practice
            </Typography>
            <Button color="inherit" onClick={()=>navigate("/logout")}>Logout</Button>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <ListItems resetUserSelectedData={resetUserSelectedData} />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {displayListItem(selectedListItem)}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
