import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const ScoreCard = ({ 
  score, 
  totalQuestion, 
  answerLog 
}) => {
  return (
    <React.Fragment>
      <Card>
        <CardHeader title="ScoreCard" />
        <Divider />
        <CardContent>
          <FormControl>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="h6">Your total score </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="h6">
                  {score} out of {totalQuestion}
                </Typography>
              </Grid>
            </Grid>
          </FormControl>
        </CardContent>
      </Card>
      {answerLog.length !== 0 ? (
      <Card sx={{ marginTop: 4 }}>
        <CardHeader title="Answers" />
        <Divider />
        <CardContent>
          <List>
            {answerLog.map((answerData, index) => (
              <div key={index}>
                <ListItem >
                  <ListItemText
                    primary={answerData.question}
                    secondary={`Your answer: ${answerData.answer}, Correct Answer: ${answerData.correctAnswer}`}
                  />
                </ListItem>
                <Divider />
              </div>
            ))}
          </List>
        </CardContent>
      </Card>
      ) : null}
    </React.Fragment>
  );
};

export default ScoreCard;
