import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";

const Question = ({
  selectedQuestionSet,
  currentQuestion,
  setCurrentQuestion,
  setScore,
  setSelectedListItem,
  setAnswerLog,
  questionSetData,
  questionData
}) => {

  const currentQuestionData = questionData.questions[currentQuestion];
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [shuffledChoices, setShuffledChoices] = useState([]);
  const questionTitle = questionData.title;

  //create one state variable which will keep array of question, answer and correct answer
  const handleAnswerChange = (event) => {
    setSelectedAnswer(event.target.value);
  };

  useEffect(() => {
    setShuffledChoices(shuffleArray(currentQuestionData.choices));
  }, [currentQuestionData.choices]);

  const shuffleArray = (array) => {
    let shuffledArray = array.slice(); // Create a copy of the array
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  //create function handle next which will increment the current question and set the score
  //Also once question array reached to end, set Score component in selectedListItem
  const handleNext = () => {

    if(selectedAnswer === null) {
      alert("Please select an answer");
      return;
    }

    if (selectedAnswer) {  
      if (selectedAnswer === currentQuestionData.correctAnswer) {
        setScore((prev) => prev + 1);
      } else {
        //store the question, answer and correct answer in answerLog
        setAnswerLog(prev => [...prev, {
          question: currentQuestionData.question,
          answer: selectedAnswer,
          correctAnswer: currentQuestionData.correctAnswer
        }]);
      }
      if (currentQuestion < questionSetData.questionCount - 1) {
        setCurrentQuestion((prev) => prev + 1);
      } else {
        setSelectedListItem("Score");
      }
    }
    setSelectedAnswer(null);
  }
  
  return (
    <Card>
      <CardHeader title={`${questionTitle} questions`} />
      <Divider />
      <CardContent>
        <FormControl>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6">Q.{currentQuestion+1} {currentQuestionData.question}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <RadioGroup 
              name="radio-buttons-group"
              onChange={handleAnswerChange}
              >
                {shuffledChoices.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option}
                    control={<Radio />}
                    label={option}
                    checked={selectedAnswer === option}
                  />
                ))}
              </RadioGroup>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button variant="contained" color="primary" onClick={handleNext}>
                Next
              </Button>
            </Grid>
          </Grid>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default Question;
