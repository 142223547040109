//add missing import
import React, {useContext} from "react";
import {
  Card,
  CardContent,
  Typography,
} from "@mui/material";

import UserContext from "../context/UserContext";

const WelcomeBoard = ({
  paramter,
}) => {
    const { authUser } = useContext(UserContext);
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          Welcome {authUser[0].firstName} さん, to the Japanese word challenge!
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          You will be presented with some Japanese words with multiple choices.
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Can you score 100%?
        </Typography>
      </CardContent>
    </Card>
  );
};

export default WelcomeBoard;
