import { createContext, useState } from "react";
import axios from "axios";

const UserContext = createContext(null);

export const UserProvider = (props) => {
  const [authUser, setAuthUser] = useState(null);

  const signIn = async (credentials) => {
    const encodedCredentials = btoa(
      `${credentials.username}:${credentials.password}`
    );

    const fetchOptions = {
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    };

    try {
      const response = await axios.get(
        "/api/users",
        fetchOptions
      );
      setAuthUser(response.data);
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const signOut = () => {
    setAuthUser(null);
  }

  return (
    <UserContext.Provider
      value={{
        authUser,
        actions: {
          signIn,
          signOut
        },
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
