//add missing import
import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";

import { mergedQuestionSet } from "../data/QuestionData";
import { shuffleArray } from "../utility/ArrayProcessing";

const QuestionBoard = ({
  questionSetData,
  setQuestionSetData,
  setSelectedListItem,
  selectedQuestionSet,
  setQuestionData,
}) => {
  const handleQuestionCountChange = (event) => {
    setQuestionSetData(prevState => ({
      ...prevState,
      questionCount: event.target.value
    }));
  };

  const handleGenerate = () => {
    if (
      (isNaN(questionSetData.questionCount) ||
      questionSetData.questionCount === 0) ||
      questionSetData.questionType === ''
    ) {
      alert("Please select a valid values from filters");
      return null;
    } else {
      setSelectedListItem("Question");
      if(questionSetData.questionType === "Random") {
        setQuestionData(prevState => ({
          ...prevState,
          questions: shuffleArray(mergedQuestionSet[selectedQuestionSet].questions),
          title: mergedQuestionSet[selectedQuestionSet].title
        }));

      } else { 
        setQuestionData(prevState => ({
          ...prevState,
          questions: mergedQuestionSet[selectedQuestionSet].questions,
          title: mergedQuestionSet[selectedQuestionSet].title
        }));
      }
    }
  };

  const handleQuestionTypeChange = (event) => {
    setQuestionSetData(prevState => ({
      ...prevState,
      questionType: event.target.value
    }));
  };

  const questionCountChoices = mergedQuestionSet[selectedQuestionSet].questionSet.map(
    (option, index) => (
      <MenuItem key={index} value={option}>
        {option}
      </MenuItem>
    )
  );

  return (
    <Card>
      <CardHeader
        title={`Question filter - ${mergedQuestionSet[selectedQuestionSet].title}`}
      />
      <Divider />
      <CardContent>
        <FormControl>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <InputLabel id="question-count-select-label">Number of Questions</InputLabel>
                <Select
                  id="question-count-select"
                  value={questionSetData.questionCount}
                  onChange={handleQuestionCountChange}
                  style={{ width: "250px" }}
                  size="small"
                  label="Number of Questions"
                  labelId="question-count-select-label"
                >
                  <MenuItem value={0}></MenuItem>
                  {questionCountChoices}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <InputLabel id="question-type-select-label">Question Type</InputLabel>
                <Select
                  id="question-type-select"
                  value={questionSetData.questionType}
                  onChange={handleQuestionTypeChange}
                  style={{ width: "250px" }}
                  size="small"
                  label="Question Type"
                  labelId="question-type-select-label"
                >
                  <MenuItem value="Sequence">Sequence</MenuItem>
                  <MenuItem value="Random">Random</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGenerate}
              >
                Generate
              </Button>
            </Grid>
          </Grid>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default QuestionBoard;
