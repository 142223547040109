import React, { useContext, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  AppBar,
  Toolbar,
  CssBaseline,
  Alert,
} from "@mui/material";

import UserContext from '../context/UserContext';
import { useNavigate } from "react-router-dom";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#E43D12", // Customize primary color
    },
    secondary: {
      main: "#dc004e", // Customize secondary color
    },
    background: {
      default: "#EBE9E1", // Customize background color
    },
  },
});

export default function Login() {
  const navigate = useNavigate();

  const { actions } = useContext(UserContext);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState("");

  const handleLogin = async (event) => {
    event.preventDefault();

    //encode credentials to base64
    const credentials = {
      username: userName,
      password: password
    };

    try {
      const user = await actions.signIn(credentials);
      if (user) {
        navigate("/dashboard");
        setErrors("");
      } else {
        setErrors("Sign-in was unsuccessful");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute">
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Nihongo mock practice
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Container maxWidth="sm">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                minHeight="50vh"
              >
                <Typography variant="h4" component="h1" gutterBottom>
                  Login
                </Typography>
                {errors !== "" ? (
                  <Alert severity="error" style={{ marginBottom: "16px" }}>
                    {errors}
                  </Alert>
                ) : null}
                <form onSubmit={handleLogin}>
                  <TextField
                    label="Username"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    size="medium"
                  />
                  <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    size="medium"
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ marginTop: "16px" }}
                    size="large"
                  >
                    Submit
                  </Button>
                </form>
              </Box>
            </Container>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
